import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <h2>About Us</h2>
      <section className="mission-vision-values">
        <div className="section">
          <h3>Our Mission</h3>
          <p>At Kwechiri Tech Solutions Ltd, our mission is to deliver innovative and reliable technology solutions that empower businesses and individuals to achieve their full potential. We strive to create cutting-edge products and services that drive efficiency, enhance user experiences, and foster growth in a rapidly evolving digital landscape.</p>
        </div>
        <div className="section">
          <h3>Our Vision</h3>
          <p>Our vision is to be a leading global technology provider recognized for transforming industries through advanced solutions and exceptional service. We aim to pioneer the future of technology, setting new standards for excellence and innovation while contributing to the success and advancement of our clients and communities.</p>
        </div>
        <div className="section">
          <h3>Our Values</h3>
          <ul>
            <li><strong>Innovation:</strong> We embrace creativity and continuously seek new ways to solve challenges and drive progress.</li>
            <li><strong>Integrity:</strong> We uphold the highest ethical standards, ensuring honesty, transparency, and accountability in all our actions.</li>
            <li><strong>Excellence:</strong> We are committed to delivering superior quality and performance in every project, striving for excellence in everything we do.</li>
            <li><strong>Collaboration:</strong> We value teamwork and foster a collaborative environment where diverse ideas and perspectives contribute to our success.</li>
            <li><strong>Customer Focus:</strong> We prioritize our clients' needs and work tirelessly to exceed their expectations, ensuring their satisfaction and success.</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default About;
