import React, { useState } from 'react';
import axios from 'axios';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://localhost:5000/api/contact', formData); // Ensure this matches your backend URL
      alert('Message sent successfully');
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Error sending message:', error);
      alert('There was an error sending the message');
    }
  };

  return (
    <div className="contact-container">
      <h2>Contact Us</h2>
      <p>If you have any questions or feedback, please fill out the form below and we'll get back to you as soon as possible. We look forward to hearing from you!</p>
      <p>For quick support, chat with us on WhatsApp:</p>
      <a href="https://wa.me/2348036682403" target="_blank" rel="noopener noreferrer" className="whatsapp-button">
        Chat with us on WhatsApp
      </a>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
        </div>
        <button type="submit" className="submit-button">Send</button>
      </form>
      <div className="contact-details">
        <h3>Our Office Address</h3>
        <p>27 Abakaliki Road, <br />Awka, Anambra State,<br />Nigeria</p>

        <h3>Phone Number</h3>
        <p><a href="tel:+2348036682403">+(234) 8036-682-403</a></p>
        <p>For quick support, chat with us on WhatsApp:</p>
        <a href="https://wa.me/2348036682403" target="_blank" rel="noopener noreferrer" className="whatsapp-button">
        Chat with us on WhatsApp
      </a>
      </div>
      <div className="social-media">
        <a href="https://facebook.com/kwechiritechnologies" target="_blank" rel="noopener noreferrer">Facebook</a>
        <a href="https://twitter.com/odogwu_kwechiri" target="_blank" rel="noopener noreferrer">Twitter</a>
        <a href="https://instagram.com/kwechiritechnologies" target="_blank" rel="noopener noreferrer">Instagram</a>
      </div>
    </div>
  );
};

export default Contact;
