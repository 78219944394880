// client/src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <h2>Kwechiri Tech Solutions</h2>
        <ul className="footer-links">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About Us</Link></li>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/contact">Contact Us</Link></li>
        </ul>
        <div className="footer-social">
          <a href="https://facebook.com/kwechiritechnologies" target="_blank" rel="noopener noreferrer">Facebook</a>
          <a href="https://twitter.com/odogwu_kwechiri" target="_blank" rel="noopener noreferrer">Twitter</a>
          <a href="https://linkedin.com/company/104249268" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        </div>
        <p>&copy; 2024 Kwechiri Tech Solutions. All rights reserved.</p>
        <a className="whatsapp-link" href="https://wa.me/2348036682403" target="_blank" rel="noopener noreferrer">Contact Us on WhatsApp</a>
      </div>
    </footer>
  );
};

export default Footer;
