// client/src/pages/Home.js
import React from 'react';
import './Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <header className="header">
        <h1>KWECHIRI TECH SOLUTIONS LTD</h1>
        <p>Your partner in innovative technology solutions and education.</p>
      </header>

      <section className="intro-section">
        <div className="intro-item">
          <h2>EVOLUTION IN TECH EDUCATION</h2>
          <p>We are transforming ICT and STEM education through innovative approaches. Our mission is to equip students with essential skills for the future, integrating practical learning with the latest technology.</p>
        </div>
        <div className="intro-item">
          <h2>EFFICIENCY IN TECH SERVICE DELIVERY</h2>
          <p>Our commitment is to deliver top-tier tech services, including web and software development, digital marketing, and more. We ensure every project is handled with precision and excellence to meet our clients' needs effectively.</p>
        </div>
      </section>
    </div>
  );
};

export default Home;
