import React from 'react';
import './Services.css';

const Services = () => {
  return (
    <div className="services-wrapper">
      <div className="services-main">
        <h2>Our Services</h2>
        <p>Explore the range of services we offer to help you succeed in the tech world.</p>
        
        <div className="services-grid">
          <div className="service-item service-web-dev">
            <h3>Web Development</h3>
            <p>We design and develop custom websites for individuals, businesses and organizations to enhance their online presence and engagement.</p>
          </div>

          <div className="service-item service-software-dev">
            <h3>Software Development</h3>
            <p>Our team builds robust, scalable software solutions tailored to your business needs, from desktop applications to complex systems.</p>
          </div>

          <div className="service-item service-mern-stack">
            <h3>MERN Stack Technologies</h3>
            <p>We specialize in MERN stack development, providing full-stack solutions using MongoDB, Express.js, React, and Node.js.</p>
          </div>

          <div className="service-item service-digital-marketing">
            <h3>Digital Marketing and Partners</h3>
            <p>Enhance your brand’s visibility and reach with our comprehensive digital marketing services, including SEO, social media, and PPC campaigns.</p>
          </div>
          
          <div className="service-item service-graphics-design">
            <h3>Graphics Design</h3>
            <p>Our graphic design services include branding, logo creation, and marketing materials that help establish your visual identity.</p>
          </div>

          <div className="service-item service-tech-consultation">
            <h3>Tech Consultation</h3>
            <p>We offer expert advice and solutions for your tech needs, from system integrations to technology strategy planning.</p>
          </div>

          <div className="service-item service-tech-academy">
            <h3>Tech Academy Partners</h3>
            <p>We collaborate with top tech academies and instructors to provide high-quality training and workshops for tech enthusiasts.</p>
          </div>

          <div className="service-item service-mentorship">
            <h3>Mentorship</h3>
            <p>Our mentorship program supports individuals and teams in achieving their tech goals through guidance and support from experienced professionals.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
